import * as React from "react"
import HeardTopWrapper from "../components/header-wrapper"
import Layout from "../components/layout/index"
import PageContainer from "../components/page-container"
import {Row, Col, Image} from "react-bootstrap"
import BlogDefaultImage from "../images/blog-defealt.png"
//import BlogImage from "../images/blog.jpg"
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';


import { Link,graphql } from "gatsby"
import { Helmet } from 'react-helmet';
function TimeFormat(dateString){
  let date = new Date(dateString)
  let months = {
    0: 'January',
    1: 'February',
    2: 'March',
    3: 'April',
    4: 'May',
    5: 'June',
    6: 'July',
    7: 'August',
    8: 'September',
    9: 'October',
    10: 'November',
    11: 'December'
  }
  return months[date.getMonth()] + " " + date.getDate() + "," + date.getFullYear()
 }
const Blog = ({ data }) => {
  const {t} = useTranslation();
  const blogData = data.allWpPost.nodes
  return(
    <>
      <HeardTopWrapper title={t('blogBannerTitle')} shortDes1={t('blogBannerDes')}/>
      <Layout>
        <Helmet>
            <title>{t('myKitchenInspector')}｜{t('blog')}</title>
        </Helmet>
        <PageContainer>
          <>
            {/* <h2 className="home-title">Blog/Case Study</h2> */}
            {
              blogData.length ? <Row>
              {blogData.map((item, index) => {
                return <Col md={6} lg={4} key={`item_${index}`} className="blog-list">
                <Link to={`../blog/${item.slug}`}>
                <div className="blog-list-item">
                  <div className="blog-img-wrapper">
                    <Image src={`${item.featuredImage ? item.featuredImage.node.link : BlogDefaultImage}`} rounded />
                  </div>
                  <h4>{item.title}</h4>
                
                  <div className="blogDes" dangerouslySetInnerHTML={{ __html: item.excerpt }}>
                </div> 
                <p className="blogDate">{TimeFormat(item.date)}</p>
                </div>
                </Link>
              </Col>
                })}
              </Row>
              :<h2 className="text-center">{t('noItemInBlog')}</h2>
            }
            
          </>
        </PageContainer>
      </Layout>
    </>
    
  )
}
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allWpPost(limit: 10) {
      nodes {
        id
        uri
        title
        content
        slug
        excerpt
        date
        featuredImage {
          node {
            uri
            link
          }
        }
      }
    }
  }
`

export default Blog
